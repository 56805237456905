<template>
    <div class="date-prompt">
      <div style="line-height: 50px;height: 50px;">
        <div style="float: left;font-size: 17px;font-weight: bold;width: 70%;">日程提醒 &nbsp;&nbsp;<span style="color: #031F88;font-size: 14px;">HOT！</span></div>
        <div class="hand op" @click="calendarShow = true" style="float: left;font-size: 14px;font-weight: bold;color: red;width: 30%;cursor: pointer;text-align: right">MORE</div>
      </div>
      <div style="width: 100%; display: flex; justify-content: center;padding-top: 10px;">
        <div class="date-left">
          <div class="date-left-top">
            <div class="date-tag-left"></div>
            <div class="date-tag-right"></div>
            <div class="date-left-top-content">{{getYear}}年{{getMonth}}月</div>
          </div>
          <div class="date-left-bottom">
            <div style="font-size: 42px; margin: 5px 0">
              {{getDay}}
            </div>
            <div style="display: flex; justify-content: center; font-size: 14px">
              <div style="margin-right: 10px">{{getWeek}}</div>
              <div>{{getLunarDay}}</div>
            </div>
          </div>
        </div>

        <div style="width: 320px">
          <div v-if="dateData.schedule.length !== 0" class="schedule-card">
            <div class="background-image"></div>
            <div style="display: flex; color: #FFFFFF; padding: 10px 10px 2px 10px">
              <div class="text-tag" style="color:#FEAA33">
                  日程提醒
              </div>
            </div>

            <div style="padding: 0 10px; font-size: 12px">
                <el-carousel direction="vertical" :autoplay="false" height="90px">
                    <el-carousel-item v-for="(item, index) in dateData.schedule" :key="index">
                        <div class="text-font" style="margin-left: 2px;font-size: 14px;line-height: 25px;color: #FFFFFF;">{{item.date.slice(0, 10)}}</div>
                        <div v-for="(ite, idx) in item.list" :key="idx">
                            <div  @click="toLookDate(ite)"  class="hand op" style="color: #FFFFFF; white-space: nowrap;overflow: hidden;text-overflow: ellipsis; line-height: 20px">{{idx+1}}.{{ite.content}}</div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
          </div>

          <div v-else>
            <div class="schedule-card">
              <div class="background-image">
              </div>
              <div style="display: flex; color: #FFFFFF; padding: 10px 14px 10px 14px">
                <div class="text-tag" style="color:#FEAA33">
                  日程提醒
                </div>
              </div>
              <div class="hand op" @click="addSchedule" style="text-align: center; font-weight: 600;color: #FFFFFF;margin: 8px 6px 0 0;font-size: 18px">
                <i class="el-icon-circle-plus-outline" style="font-size: 20px; vertical-align: middle; margin-right: 2px"></i><span>添加日程</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="width: 100%; margin-top: 15px">
        <el-carousel v-if="dateData.warn.length > 0" height="120px" direction="vertical" :autoplay="true">
          <el-carousel-item v-for="(item, index) in dateData.warn" :key="index">
            <div class="tips-card hand op" @click="toLookDate(item)" :style="{background: tipsType.find(option => {return option.value === item.warnType}).background}">
              <div style="width: 84%;">
                <div style="display: flex; margin-bottom: 8px">
                  <div class="text-tag" :style="{color: tipsType.find(option => {return option.value === item.warnType}).color}">
                    重要提醒
                  </div>
                  <div class="text-font" style="width: 80%; margin-left: 10px; display: -webkit-box; -webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;text-overflow: ellipsis" v-if="item.dayCount == 1">{{item.content}}</div>
                  <div v-else class="text-font" style="width: 80%; margin-left: 10px;display: -webkit-box; -webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;text-overflow: ellipsis">今天距：{{item.content}}</div>
                </div>
                <div>还剩{{item.dayCount}}天（{{item.date.slice(0, 10)}}）</div>
              </div>
              <img :src="require('@/assets/imgs/index/' + tipsType.find(option => {return option.value === item.warnType}).icon)" style="width: 60px; height: 60px; border-radius: 5px;opacity: 0.5">
            </div>
          </el-carousel-item>
        </el-carousel>
        <div v-else>
          <div class="tips-card" style="background: linear-gradient(229deg, #FF444C 0%, #D60D17 100%);">
            <div style="width: 84%;">
              <div style="display: flex;">
                <div class="text-tag" style="color:#DC151F">
                  重要提醒
                </div>
              </div>
              <div class="hand op" @click="addReminder" style="text-align: center;margin-left: 40px; font-weight: 600;color: #FFFFFF;font-size: 18px">
                <i class="el-icon-circle-plus-outline" style="font-size: 20px; vertical-align: middle; margin-right: 2px"></i><span>重要提醒</span>
              </div>
            </div>
            <img src="@/assets/imgs/index/importance.png" style="width: 60px; height: 60px; border-radius: 5px;">
          </div>
        </div>
      </div>
      <!--添加日程-->
      <el-dialog :modal-append-to-body="false" :visible.sync="dateShow" width="430px" :show-close="false" class="event-form" :close-on-click-modal="false">
        <div class="top" style="text-align: center;">
          添加日程
          <i class="el-icon-close hand op" style="font-size: 24px;position: absolute;right: 20px;top: 14px;cursor: pointer;" @click="dateShow = false"></i>
        </div>
        <div style="padding: 20px 30px; overflow: hidden">
          <div>
            <div class="title-form">
              选择提醒日期
            </div>
            <div>
              <el-date-picker
                  v-model="selectDate"
                  type="date"
                  format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                  placeholder="选择日期">
              </el-date-picker>
            </div>
          </div>
          <div style="margin-top: 30px">
            <div class="title-form">
              添加日程
            </div>
            <div>
              <el-input
                  type="textarea"
                  :rows="5"
                  :maxlength="50"
                  placeholder="请输入日程详情"
                  v-model="selectContent">
              </el-input>
            </div>
          </div>
          <div style="display: flex; justify-content: center; margin: 26px 0 6px 0">
            <div class="btn hand op" @click="addDate">添加日程提醒</div>
          </div>
        </div>
      </el-dialog>

      <!--添加提醒-->
      <el-dialog :modal-append-to-body="false" :visible.sync="tipsShow" width="430px" :show-close="false" class="event-form" :close-on-click-modal="false">
        <div class="top" style="text-align: center;">
          添加重要提醒
          <i class="el-icon-close hand op" style="font-size: 24px;position: absolute;right: 20px;top: 14px;cursor: pointer;" @click="tipsShow = false"></i>
        </div>
        <div style="padding: 20px 30px; overflow: hidden">
          <div>
            <div class="title-form">
              选择提醒图标
            </div>
            <div style="display: flex">
              <div style="width: 34px; text-align: center; margin-right: calc((100% - 170px) / 4)" v-for="(item, index) in tipsType" :key="index">
                <img class="title-icon" :src="require('@/assets/imgs/index/' + item.path)">
                <div class="">{{item.name}}</div>
                <el-radio v-model="tipsData.type" :label="item.value"></el-radio>
              </div>
            </div>
          </div>
          <div style="margin-top: 26px">
            <div class="title-form">
              选择提醒日期
            </div>
            <div>
              <el-date-picker
                  v-model="tipsData.date"
                  type="date"
                  :picker-options="pickerOptions"
                  placeholder="选择日期">
              </el-date-picker>
            </div>
          </div>
          <div style="margin-top: 30px">
            <div class="title-form">
              重要提醒详情
            </div>
            <div>
              <el-input
                  type="textarea"
                  :rows="5"
                  :maxlength="50"
                  placeholder="请输入日程详情"
                  v-model="tipsData.content">
              </el-input>
            </div>
          </div>
          <div style="display: flex; justify-content: center; margin: 26px 0 6px 0">
            <div class="btn hand op" @click="addTips">添加重要提醒</div>
          </div>

        </div>
      </el-dialog>

      <!--更多日历-->
      <el-dialog :modal="false" top="110px" :close-on-click-modal="false" :visible.sync="calendarShow" width="920px" :show-close="false" class="event-form">
        <i class="el-icon-close hand op" style="z-index: 1000; font-size: 24px;position: absolute;right: 20px;top: 8px;cursor: pointer;" @click="calendarShow = false"></i>
        <div style="overflow: hidden">
          <Calendats :dates="selectLook" v-if="calendarShow" @childEvent="handleChildEvent"></Calendats>
        </div>
      </el-dialog>
    </div>
</template>

<script>
import { getLunar } from 'chinese-lunar-calendar'
import Calendats from "@/components/common/Calendats.vue";
export default {
  name: 'DatePrompt',
  components: {Calendats},
  data() {
    return {
      // 日历
      getYear: '',
      getMonth: '',
      getDay: '',
      getWeek: '',
      getLunarDay: '',
      calendarShow: false,

      dateList: [],
      tipsList: [],
      tipsShow: false,
      dateShow: false,

      // 选择日期
      selectLook: '',

      // 日程
      selectDate: '',
      selectContent: '',

      // 提醒
      tipsType: [
        {path: 'importance.png', icon: 'importanceIcon.png', name: '默认', value: 1, color: '#DC151F', background: 'linear-gradient(229deg, #FF444C 0%, #D60D17 100%)'},
        {path: 'record.png', icon: 'recordIcon.png', name: '考试', value: 2, color: '#2970FF', background: 'linear-gradient(229deg, #2970FF 0%, #074BD4 100%)'},
        {path: 'evection.png', icon: 'eventionIcon.png', name: '出差', value: 3, color: '#38B781', background: 'linear-gradient(229deg, #38B781 0%, #048B52 100%)'},
        {path: 'festival.png', icon: 'festivalIcon.png', name: '生日', value: 4, color: '#FF9800', background: 'linear-gradient(229deg, #FF9800 0%, #FE6D1A 100%)'},
        {path: 'project.png', icon: 'projectIcon.png', name: '开会', value: 5, color: '#FFD82A', background: 'linear-gradient(229deg, #FFD82A 0%, #FFAF2D 51%, #FC8F3A 100%)'},
      ],
      tipsData: {
        type: 1,
        date: '',
        content: ''
      },

      type: 0,
      dateData: {
      schedule: [],
      warn: [],
      dateTime:'',
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
    }
  },

  computed: {
    is_login(){
      return this.$store.state.is_login
    },
    user() {
      return this.$store.state.user;
    },
    calendarList(){
      return this.$store.state.calendarList;
    }
  },
  watch:{
    is_login(newDate,oldDate){
      if(newDate == 1){
        this.getDateList();
      }else{
        this.dateData = {schedule: [],warn: []};
      }
    }
  },
  created(){

  },
  mounted(){
    this.getCurrentTime();
    this.getDateList()
  },
  methods: {
    handleChildEvent() {
      this.getDateList();
    },
    // 查看
    toLookDate(item) {
      this.calendarShow = true;
      this.selectLook = item.date
    },

    // 获取展示列表
    getDateList() {
      var that = this;
      this.newApi.getCalendarEventList({type: 1,uuid:this.user.uuid}).then(res => {
        let schedule = res.data.schedule;
        if(schedule.length > 0){
            that.dateTime = that.utils.formatDate(schedule[0].date,2);
        }
        var data = res.data.warn;
        if(data.length > 0){
          for (var i = 0; i < data.length; i++){
            var time1 = Math.round(new Date(data[i].date).getTime() / 1000);
            var time2 = Math.round(new Date().getTime() / 1000);
            var timeDiffInSeconds = time2 - time1;
            var timeDiffInDays = Math.floor(timeDiffInSeconds / (24 * 60 * 60));
            if(timeDiffInDays == 0){
              res.data.warn[i].dayCount = timeDiffInDays;
            }else{
              res.data.warn[i].dayCount = timeDiffInDays * -1;
            }
          }
        }
        this.dateData = res.data;
      })
    },

    //点击添加日程按钮
    addSchedule(){
      if(!this.is_login){
        this.utils.msg("请先登录");
        return false;
      }
      this.dateShow = true;
    },

    // 添加日程
    addDate() {
      if (!this.selectDate) {
        this.utils.msg('请选择提醒日期')
        return
      }
      if (!this.selectContent) {
        this.utils.msg('请输入日程详情')
        return
      }
      let params = {
        type: 1,
        content: this.selectContent,
        typeTwo: 1,
        date: this.changeDate(this.selectDate) + ' 00:00:00'
      }
      this.newApi.addCalendarEvent(params).then(res =>{
        if (res.isSuccess === 1) {
          this.utils.sus(res.data);
          this.dateShow = false;
          this.getCurrentTime();
          this.getDateList();
        }
      })
    },

    //点击添加提醒按钮
    addReminder(){
      if(!this.is_login){
        this.utils.msg("请先登录");
        return false;
      }
      this.tipsShow = true
    },

    // 添加提醒
    addTips() {
      if (!this.tipsData.date) {
        this.utils.msg('请选择提醒日期')
        return
      }
      if (!this.tipsData.content) {
        this.utils.msg('请输入重要提醒详情')
        return
      }
      let params = {
        type: 1,
        content: this.tipsData.content,
        typeTwo: 2,
        warnType: this.tipsData.type,
        date: this.changeDate(this.tipsData.date) + ' 00:00:00'
      }
      this.newApi.addCalendarEvent(params).then(res =>{
        if (res.isSuccess === 1) {
          this.utils.sus(res.data);
          this.tipsShow = false;
          this.getCurrentTime();
          this.getDateList();
        }
      })
    },

    // 转换
    changeDate(date) {
      let getYear = date.getFullYear();
      let getMonth =  (date.getMonth() + 1).toString().padStart(2, '0');
      let getDay = (date.getDate()).toString().padStart(2, '0');
      return `${getYear}-${getMonth}-${getDay}`
    },

    getCurrentTime() {
      //获取当前时间并打印
      this.getYear = new Date().getFullYear();
      this.getMonth =  new Date().getMonth() + 1;
      this.getDay = new Date().getDate();
      let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ]
      this.getWeek = weekArr[new Date().getDay()];
      this.getLunarDay = getLunar(this.getYear, this.getMonth, this.getDay).dateStr
    },
  }
}

</script>

<style scoped>
  .date-prompt {
    width: 100%;
    padding-top: 5px;
  }

  .date-prompt .date-left {
    width: 124px;
    height: 130px;
    background-color: #FFFFFF;
    border-radius: 10px;
    margin-right: 12px;
    box-shadow: 0 0 6px 1px rgba(0,0,0,0.16);
  }

  .date-prompt .date-left .date-left-top {
    width: 100%;
    height: 35px;
    border-radius: 10px 10px 0 0;
    background-color: #E84929;
    position: relative;
  }

  .date-prompt .date-left .date-left-top .date-tag-left {
    height: 11px;
    width: 3px;
    position: absolute;
    left: 22%;
    border-radius: 0 0 2px 2px;
    background-color: #FFFFFF;
  }

  .date-prompt .date-left .date-left-top .date-tag-right {
    height: 11px;
    width: 3px;
    position: absolute;
    right: 22%;
    border-radius: 0 0 2px 2px;
    background-color: #FFFFFF;
  }

  .date-prompt .date-left .date-left-top .date-left-top-content {
    color: #FFFFFF;
    font-weight: 600;
    text-align: center;
    font-size: 14px;
    padding-top: 10%;
  }

  .date-prompt .date-left .date-left-bottom {
    text-align: center;

  }

  .schedule-card {
    width: 290px;
    height: 130px;
    position: relative;
    background: linear-gradient(227deg, #FEAA33 0%, #FE6B19 100%);
    box-shadow: 0 0 6px 1px rgba(0,0,0,0.16);
    border-radius: 10px 10px 10px 10px;
  }

  /deep/ .el-carousel__indicator--vertical {
    padding: 4px 8px;
  }

  /deep/ .el-carousel__indicator--vertical .el-carousel__button {
    background-color: #D9D9D9;
  }

  .schedule-card .background-image {
    width: 110px;
    height: 103px;
    background-image: url("../../../assets/imgs/index/dateBackgound.png");
    position: absolute;
    top: 10%;
    right: 2%;
  }

  .tips-card {
    width: 398px;
    height: 100px;
    display: flex;
    justify-content: space-between;
    color: #FFFFFF; padding: 10px 14px 10px 14px;
    box-shadow: 0 0 6px 1px rgba(0,0,0,0.16);
    border-radius: 10px 10px 10px 10px;
  }

  .date-prompt .text-tag {
    width: 68px;
    height: 20px;
    text-align: center;
    background-color: #FFFFFF;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    padding: 2px 0;
  }

  .date-prompt .btn {
    width: 130px;
    text-align: center;
    padding: 4px 0;
    background-color: #2970FF;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 16px;
    border-radius: 5px;
  }

  .event-form .top {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #444444;
    border: 1px solid #CECFD0;
    height: 50px;
    box-sizing: border-box;
    line-height: 50px;
  }

  .event-form .title-form {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
  }

  /deep/ .event-form .el-dialog__header {
    padding: 0;
  }

  /deep/ .event-form .el-dialog__body {
    padding: 0;
  }

  /deep/ .event-form .el-radio__label {
    display: none;
  }

  /deep/ .event-form .el-dialog {
    margin-bottom: 10px;
  }

</style>
